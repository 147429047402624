import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { logIn } from '@fingo/lib/apollo/reactive-variables/localUpdates';
import { OAUTH_LOGIN } from '@fingo/lib/graphql';
import Box from '@mui/material/Box';
import Cookies from 'universal-cookie';
import { useLocation } from 'react-router-dom';
import GoogleButton from 'react-google-button';
import { useGoogleLogin } from '@react-oauth/google';
import PropTypes from 'prop-types';
import './google-button.css';
import { useTracking } from 'react-tracking';
import { useSnackBars } from '@fingo/lib/hooks';

const GoogleSign = ({ label }) => {
  const { trackEvent } = useTracking();
  const cookies = new Cookies();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const utmCampaign = cookies.get('utm_campaign') || searchParams.get('utm_campaign') || null;
  const { addAlert } = useSnackBars();
  const onError = useCallback(() => {
    addAlert({
      id: 'oauth-error',
      message: 'Ha ocurrido un error en tu proceso de autenticación.',
    });
  }, []);
  const [oauthLogin, { loading }] = useMutation(OAUTH_LOGIN, {
    variables: {
      provider: 'google',
      utmCampaign,
    },
    onCompleted: ({ oauth2Auth }) => logIn(oauth2Auth),
    onError,
  });
  const onGoogleClick = useGoogleLogin({
    onSuccess: (codeResponse) => oauthLogin({
      variables: {
        idToken: codeResponse.code,
      },
    }),
    scope:
      'openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.phonenumbers.read',
    flow: 'auth-code',
  });
  const onClick = useCallback(() => {
    trackEvent({
      action: 'CLICK',
      button: 'google-oauth',
    });
    onGoogleClick();
  }, [onGoogleClick]);
  return (
    <Box width="100%">
      <GoogleButton
        disabled={loading}
        label={label}
        type="light"
        onClick={onClick}
        className="google-button"
        style={{
          width: '100%',
        }}
      />
    </Box>
  );
};

GoogleSign.propTypes = {
  label: PropTypes.string,
};

GoogleSign.defaultProps = {
  label: 'Continuar con Google',
};

export default React.memo(GoogleSign);
