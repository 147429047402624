import AccountBalance from '@mui/icons-material/AccountBalance';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingButton } from '@mui/lab';
import AccountsListWithAdd from '../bank/AccountsListWithAdd';
import { useConfirmOrdering } from '../../hooks';

const ConfirmOfferDialog = ({ open, onClose }) => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [confirmOrdering, { loading: loadingConfirm }] = useConfirmOrdering(
    selectedAccount,
    onClose,
  );
  const [creatingNewAccount, setCreatingNewAccount] = useState(false);
  const handleSelectedBank = (account) => {
    setSelectedAccount((prevId) => {
      if (prevId === account.id) return null;
      return account.id;
    });
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ bgcolor: 'primary.main', px: 3 }}>
        <Stack spacing={2} alignItems="center">
          <AccountBalance fontSize="large" sx={{ color: 'white' }} />
          <Typography variant="h5" color="white">
            Selecciona la cuenta bancaria donde recibirás el giro
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <AccountsListWithAdd
          setSelectedAccountInParent={handleSelectedBank}
          xs={12}
          setCreatingNewAccount={setCreatingNewAccount}
          withSelect
        />
      </DialogContent>
      {!creatingNewAccount && (
        <DialogActions>
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={confirmOrdering}
            loading={loadingConfirm}
          >
            Continuar
          </LoadingButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

ConfirmOfferDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ConfirmOfferDialog;
