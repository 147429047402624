import React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import { formatMoney } from '@fingo/lib/helpers';

const DocumentTable = ({ documents }) => (
  <TableContainer
    component={Box}
    sx={(theme) => ({
      borderRadius: 2,
      border: `0.3px solid ${theme.palette.text.main}`,
      p: 2,
      maxHeight: 180,
      overflowX: 'auto',
    })}
  >
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Número OC</TableCell>
          <TableCell>Comprador</TableCell>
          <TableCell>Monto</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {documents.map((document) => (
          <TableRow sx={{ bgcolor: 'inherit' }} key={document.id}>
            <TableCell>{document.orderNumber}</TableCell>
            <TableCell>{document.purchaser.name}</TableCell>
            <TableCell>${formatMoney(document.totalAmount)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

DocumentTable.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    orderNumber: PropTypes.string.isRequired,
    totalAmount: PropTypes.number.isRequired,
    purchaser: PropTypes.shape({
      id: PropTypes.string.isRequired,
      rut: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  })).isRequired,
};

export default DocumentTable;
